<template>
  <v-container class="reports">
    <v-breadcrumbs :items="items"></v-breadcrumbs>

    <ReportList/>
  </v-container>
</template>

<script>
import ReportList from '@/components/ReportList.vue';

export default {
  name: 'Reports',
  metaInfo: {
    title: 'Reports',
  },
  data: () => ({
    items: [
      {
        text: 'AppHub',
        exact: true,
        to: '/',
      },
      {
        text: 'Reports',
        exact: true,
        to: '/reports',
      },
    ],
  }),
  components: {
    ReportList,
  },
};
</script>
