<template>
  <div class="reports-list">
    <v-container>
      <h1 class="display-1 mb-2">Reports</h1>
      <v-row align="stretch">
        <v-col cols="12" xs="6" sm="6" md="4" lg="3" xl="2"
          v-for="item in filteredItems" :key="item.title"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              :to="item.url"
              style="height:100%"
            >
              <v-img
                :alt="item.title"
                class="grow"
                contain
                :src="item.image"
              />
              <v-card-title>{{item.title}}</v-card-title>
              <v-card-text class="text--primary">{{item.text}}</v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'ReportsList',
  data: () => ({
    items: [
      {
        title: 'Loss Run Report',
        image: require('@/assets/underwriting_reports_beta.png'), // eslint-disable-line
        url: '/reports/loss-run-report',
        text: 'Loss run report insights.',
        adminRequired: true,
      },
      {
        title: 'Call Detail Report',
        image: require('@/assets/underwriting_reports.png'), // eslint-disable-line
        url: '/reports/call-detail-report',
        text: 'Insight into your API call requests.',
        adminRequired: false,
      },
      {
        title: 'User Feedback Report',
        image: require('@/assets/underwriting_reports.png'), // eslint-disable-line
        url: '/reports/user-feedback-report',
        text: 'User feedback recieved on a given risk.',
        adminRequired: false,
      },
    ],
  }),
  created() {
    // Get app access from store state
    this.items[0].adminRequired = !this.$store.state.isAdmin;
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => !item.adminRequired);
    },
  },
};
</script>
